.project-list {
    margin: 5rem;
}

.project-list-buttons button{
    margin-right: 10px;
}

.project-list-sort-button{
    cursor: pointer;
}

.project-list-filter-input{
    width: 50%;
    margin-left: 10px;
}