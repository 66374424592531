.logo {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    margin: 0 20px;
    width: 150px;
}
.logo-icon {
    color: white;
    font-size: 30px;
    margin: 0 10px;
}
.logo-text {
    color: white;
}
.top-header{
    background-color: #001529;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 70%;
}