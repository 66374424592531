.codebook-item-name{
    margin-left: 10px;
}

.codebook-item-check-box{
    margin-right: 5px;
}

.codebook-button:nth-child(n+3) {
    margin-left: 10px;
}

.codebook-item-code-input{
    width: 50px;
}

.codebook-item-button{
    margin-left: 10px;
}
.codebook-editing-message{
    color: #FF9800;
}

.code-number{
    min-width: 30px;
}

.checked-tiles-row{
    display: flex;
}
.selected-codes{
}
.fixed {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
}

.codebook-item-row-button {
    margin-left: 5px;
}

.codebook-item-right {
    display: flex;
}
.checked-tiles-row-fixed{
    position: fixed;
    z-index: 1;
    background: white;
    width: 480px;
}

.answer-filter-input{
    width: 45%;
}
.action-buttons{
    width: 105px;
}