.manual-coding-half{
    padding: 20px;
}
.manual-coding-action-btn{
    margin-left: 20px;
}
.manual-coding-btn-section{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.manual-coding-header{
    display: flex;
    justify-content: space-between;
}