.code-number {
    min-width: 20px;
    text-align: center;
    color: white;
    margin: 2px;
    padding-left: 4px;
    padding-right: 4px;
}
.tile-close-button{
    width: 9px;
    height: 9px;
    padding: 0;
    display: flex;
    alignItems: center;
    justifyContent: center;
    fontSize: '5px';
    position: absolute;
    top: 0;
    right: -9px;
    background-color: transparent !important;
    border: none;
}