.success-container {
    width: 100%;
    margin: 1rem;
    border: 2px solid green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.success-message {
    margin-top: 20px;
    text-align: center;
}

.reference-data-upload-section {
    display: flex;
    justify-content: space-around;

}
.reference-data-upload-section .ant-btn-primary{
    width: 180px;
    margin-top: 10px;
}

.upload-unit {
    width: 100%;
    margin: 1rem;
}

.confirm-reference{
    display: flex;
    justify-content: center;
}