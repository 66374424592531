.answer-tile-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    flex-direction: column;
}
.checked-tiles-row{
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.tiles-close-button{
    width: 9px;
    height: 9px;
    padding: 0;
    display: flex;
    alignItems: center;
    justifyContent: center;
    fontSize: '5px';
    position: absolute;
    top: 0;
    right: -9px;
    background-color: transparent !important;
    border: none;
}

.answer-filter-input {
    margin-bottom: 5px;
}

.codebook-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.codebook-item-left {
    display: flex;
    align-items: center;
    width: 90%;
}

.codebook-modal {
    max-height: 80vh;
}

.answer-button-edit {
    margin-left: 10px;
}
.answer-button-uncheck {
    margin-left: 10px;
}

.answer-filter-section{
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.answer-filter-item{
    width: 45%;
    margin: auto auto auto 0;
}
.answer-button-sort{
    width: 140px;
    margin: auto auto auto 0;;
}
.answer-sort-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.answer-marked-icon-true{
    color: orange;
}
.answer-marked-icon-false{
    color: white;
}
.answer-button-filter-mark-false{
    background-color: orange;
}
.answer-marked-icon{
    color: orange;
}
.mark-button-filter{
    margin-bottom: 5px;
}
.codebook-item-new{
    background-color: orange;
    padding: 5px;
    margin-right: 10px;
}

.checked-tiles{
    display: flex;
    justify-content: space-between;
    margin: 0.5rem;
    align-items: center;
}

.checked-tiles-content{
    margin-left: 0.5rem;
}
.answer-button{
    margin-left: 0.5rem;
}

.answer-action-buttons{
    width: 105px;
}